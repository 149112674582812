import {mapActions, mapGetters} from 'vuex'
import { doBaiduGet,doBaidu } from '@/api/core'
import cmyUtils from '@/utils/cmyUtils.js'
import dateUtils from '@/utils/dateUtils.js'

export default {
    name: "robotConsult",
    data() {
        return {
            readonly: false, // 只读模式
            chat_data: [], // 聊天记录
            query: null, // 发送内容
            channel: 'web',
            channels_select: ['wechat', 'web', 'cc'],
            session_id:null,

            myQuestionList: [],
            myQuestionListEnd: false,
            myQuestionListPage: 1,
            myQuestionListLimit: 10,
            myQuestionListIndex: 0,
        }
    },
    computed: {
        ...mapGetters([
            'winSize',
            'staffId',
            'myInfo'
        ])
    },

    mounted() {
        const that = this
        if (that.$route.params.query) {
            that.query = that.$route.params.query
            that.chat(() => {
                that.queryMyAIconsults(1,() => {
                    that.showChatData(0)
                })
            })
        } else {
            that.queryMyAIconsults(1,() => {
                that.showChatData(0)
            })
        }
    },
    methods: {
        goBack() {
            const that = this
            that.$router.go(-1)
        },

        queryMyAIconsults(page = 1, func) {
            const that = this
            var temp = [...that.myQuestionList]
            var hide = that.$message.loading('请求中...',0)
            doBaidu({
                url: cmyUtils.buildUnitPath('smart-unit-chat/list'),
                userId: that.staffId,
                page: page || 1,
                limit: that.myQuestionListLimit
            }).then(res => {
                if (res.errcode == that.$consts.biz.OK) {
                    if(!page || page == 1) {
                        temp = res.data || []
                    }else {
                        temp = [...temp,...res.data]
                    }
                    that.myQuestionList = temp
                    that.myQuestionListPage = page || 1
                    that.myQuestionListEnd = !res.data ? false : (res.data.length < that.myQuestionListLimit)
                    if(page == 1 && that.myQuestionList[0].isClose == 0 && that.myQuestionListIndex == 0) {
                        that.readonly = false
                    }
                    if (func) {
                        func()
                    }
                } else {
                    that.$message.error('查询失败')
                }
            }).finally(() => {
                hide()
            })
        },
        showChatData(index) {
            const that = this
            that.myQuestionListIndex = index
            if (that.myQuestionListPage == 1 && index == 0 && that.myQuestionList[0].isClose == 0) {
                that.readonly = false
            } else {
                that.readonly = true
            }
            var hide = that.$message.loading('请求中...',0)
            doBaidu({
                url: cmyUtils.buildUnitPath('smart-unit-chat/message/list'),
                chatId: that.myQuestionList[index].chatId
            }).then(res => {
                if (res.errcode == that.$consts.biz.OK) {
                    that.chat_data = res.data.map(msg => that.parseMsg(msg))
                } else {
                    that.$message.error('查询失败')
                }
            }).finally(() => {
                hide()
            })
        },

        parseMsg(msg) {
            const that = this
            var actions = JSON.parse(msg.message)
            if (msg.actionType == 'query') {
                return {
                    direction: 'r',
                    msg: actions.content,
                    time: msg.createTime,
                    show_toolbar: false
                }
            }

            if (!that.session_id) {
                that.session_id = msg.aiSessionId
            }

            var answer = ''
            if (actions.type == 'satisfy' || actions.type == 'failure'){
                answer = answer + actions.say;
            } else if (actions.type == 'guide' || actions.type == 'faqGuide') {
                if(that.channel != 'cc') {
                    answer = answer + actions.say;
                    for (let i = 0; i < actions.option_list.length; i++)
                        answer = answer + '<div id="chatdata' + that.chat_data.length + '_' + i + '" style="color:#1d88fa;margin:5px 0;cursor:pointer;">' + actions.option_list[i].option + '</div>'
                } else {
                    if (actions.type == 'faqGuide') {
                        answer = answer + '请问您是否想了解如下问题：' + '<span id="chatdata' + that.chat_data.length + '_0' + '" style="color:#1d88fa;font-weight: bold;margin:5px 0;">' + actions.option_list[0].option + '？</span>'
                    } else if (actions.type == 'guide') {
                        answer = answer + '请问您想了解哪方面的问题，您可以对我说：'
                        for (let i = 0; i < actions.option_list.length; i++) {
                            answer = answer + '<span id="chatdata' + that.chat_data.length + '_' + i + '" style="color:#1d88fa;margin:5px 0;">' + actions.option_list[i].option + '</span>'

                            if (i != actions.option_list.length - 1)
                                answer = answer + '、'
                        }
                    }
                }
            } else if (actions.type == 'clarify') {
                answer = answer + actions.say
            } else if (actions.type == 'event') {
                answer = answer + actions.say
            } else {
                answer = answer + '<span style="color:#e93d52;">发生系统故障啦。</span>'
            }

            that.$nextTick(() => {
                setTimeout(()=>{
                    var ele = document.getElementById('chat-body')
                    ele.scrollTop = ele.scrollHeight;

                    if (actions.option_list && actions.option_list.length > 0) {

                        for(let i=0;i<actions.option_list.length;i++) {
                            let answerNode = document.getElementById("chatdata" + (that.chat_data.length - 1)  + '_' + i);
                            if (answerNode)
                                answerNode.onclick = () => {
                                    that.query = answerNode.innerText;
                                    that.chat();
                                }
                        }
                    }

                },200)
            })

            return {
                direction: 'l',
                msg: answer,
                time: msg.createTime,
                show_toolbar: actions.type == 'satisfy' ? true : false
            }
        },

        chat(func) {
            const that = this

            if (!that.query || that.query.trim() == '' || that.readonly) {
                return
            }

            var query = that.query
            that.chat_data.push({
                direction: 'r',
                msg: query,
                time: dateUtils.getNow(),
                show_toolbar: false
            })
            that.query = null

            var hide = that.$message.loading('请求中...',0)
            doBaidu({
                url: cmyUtils.buildUnitPath('uskit/bot/chat'),
                query: query,
                userId: that.staffId,
                sessionId: (that.session_id?that.session_id:''),
                chatType: that.channel,
                sluTags: that.channels_select,
                devMode: true
            }).then(res => {

                if (res.errcode == that.$consts.biz.OK) {

                    //机器人回答处理
                    var actions = res.data;
                    var answer = '';

                    //保存sessionid
                    that.session_id = actions.session_id;

                    if (actions.type == 'satisfy' || actions.type == 'failure'){
                        answer = answer + actions.say;
                    }

                    else if (actions.type == 'guide' || actions.type == 'faqGuide'){

                        if(that.channel != 'cc') {
                            answer = answer + actions.say;
                            for (let i = 0; i < actions.option_list.length; i++)
                                answer = answer + '<div id="chatdata' + that.chat_data.length + '_' + i + '" style="color:#1d88fa;margin:5px 0;cursor:pointer;">' + actions.option_list[i].option + '</div>'
                        }
                        else {

                            if (actions.type == 'faqGuide')
                                answer = answer + '请问您是否想了解如下问题：' + '<span id="chatdata' + that.chat_data.length + '_0' + '" style="color:#1d88fa;font-weight: bold;margin:5px 0;">' + actions.option_list[0].option + '？</span>'
                            else if (actions.type == 'guide') {

                                answer = answer + '请问您想了解哪方面的问题，您可以对我说：'
                                for (let i = 0; i < actions.option_list.length; i++) {
                                    answer = answer + '<span id="chatdata' + that.chat_data.length + '_' + i + '" style="color:#1d88fa;margin:5px 0;">' + actions.option_list[i].option + '</span>'

                                    if (i != actions.option_list.length - 1)
                                        answer = answer + '、'
                                }

                            }
                        }

                    }

                    else if (actions.type == 'clarify'){
                        answer = answer + actions.say ;
                        // answer = answer + '<div class="robot-addinfo">澄清：' + actions.options[0].info.name + '【' + actions[i].options[0].option + '】' + '</div>'
                    }

                    else if (actions.type == 'event'){
                        answer = answer + actions.say ;
                        // answer = answer + '触发事件：' + actions[i].custom_reply.func;
                    }

                    else
                        answer = answer + '<span style="color:#e93d52;">发生系统故障啦。</span>';


                    that.chat_data.push({
                        direction: 'l',
                        msg: answer,
                        debug: actions.debug_info,
                        time: dateUtils.getNow(),
                        show_toolbar: actions.type == 'satisfy' ? true : false
                    })


                    setTimeout(()=>{
                        var ele = document.getElementById('chat-body')
                        ele.scrollTop = ele.scrollHeight;

                        if (actions.option_list && actions.option_list.length > 0) {

                            for(let i=0;i<actions.option_list.length;i++) {
                                let answerNode = document.getElementById("chatdata" + (that.chat_data.length - 1)  + '_' + i);
                                if (answerNode)
                                    answerNode.onclick = () => {
                                        that.query = answerNode.innerText;
                                        that.chat();
                                    }
                            }
                        }

                    },200)

                } else {
                    that.$message.error(res.errmsg)
                }
            }).finally(() => {
                hide()
                if (func) {
                    func()
                }
            })
        }
    }
}